import React from 'react';


export class Fumigacija extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.fumigation;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-10.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.fumigation}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                        <li><a href="#service-category-2">{l.serviceCategory2}</a></li>
                        <li><a href="#service-category-3">{l.serviceCategory3}</a></li>
                        <li><a href="#pest-categories">{l.serviceCategory4}</a></li>
                    </ul>
                </section>

                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText11}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText12}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText13}
                            </p>
                        </div>
                    </div>

                    <div id="service-category-2">
                        <div className="service-category-header">{l.serviceCategory2}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText21a}<br/>
                                {l.serviceCategoryText22a}<br/>
                                {l.serviceCategoryText23a}<br/>
                                {l.serviceCategoryText24a}<br/>
                                {l.serviceCategoryText25a}<br/>
                                {l.serviceCategoryText26a}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText21b}<br/>
                                {l.serviceCategoryText22b}<br/>
                                {l.serviceCategoryText23b}<br/>
                                {l.serviceCategoryText24b}<br/>
                                {l.serviceCategoryText25b}<br/>
                                {l.serviceCategoryText26b}
                            </p>
                        </div>
                    </div>

                    <div id="service-category-3">
                        <div className="service-category-header">{l.serviceCategory3}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText31}
                            </p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export const FumigacijaPests = props => {
    const lang = require('.././langs/' + props.lang + '.json');
    const l = lang.fumigationPests;

    return (
        <section className="pests-category-section">
            <div id="pest-categories" className="clearfix">
                <div className="pest-category-header">{l.head}</div>
                <p className="pest-category-description">{l.desc}</p>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest1.head.toUpperCase()}
                        </h2>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/betonski-silosi.jpg")} alt="" />
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest2.head.toUpperCase()}
                        </h2>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/metlni-silosi.jpg")} alt="" />
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest3.head.toUpperCase()}
                        </h2>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/brodski-slep.jpg")} alt="" />
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest4.head.toUpperCase()}
                        </h2>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/mlin2.jpg")} alt="" />
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest5.head.toUpperCase()}
                        </h2>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/skladista.jpg")} alt="" />
                </div>
            </div>
        </section>
    )
};


