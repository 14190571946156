import React from 'react';
import data from './data/listBlogCepa.json';

const ListItem = props =>(
        <div className="cepa-blog-list-item">
            <div className="cepa-blog-list-item-wrapper">
                <p className="cepa-blog-list-item-description">
                    {props.text}
                </p>
                <div className="cepa-blog-list-item-date">{props.date}</div>
            </div>
        </div>
);


class MS extends React.Component {

    constructor() {
        super();
        let arr = {};
        data.forEach(el=>{
            arr[el.year]=true;
        });
        this.state = {
            list: data,
            selectAll: true,
            checkbox :  arr
        };
    };

    displayCBoxes= () => {
        let cBoxList = ([
            <div className="sidebar-blog-checkbox-wrapper" key={"all"}>
                <label className="sidebar-blog-checkbox-container">Sve objave
                    <input type="checkbox" onChange={this.onClickToogleAll} checked={this.state.selectAll}/>
                    <span className="checkmark"></span>
                </label>
            </div>
        ]);
        Object.keys(this.state.checkbox).forEach((key)=>{
            cBoxList.push(
                <div className="sidebar-blog-checkbox-wrapper" key={key}>
                    <label className="sidebar-blog-checkbox-container">{key}
                        <input type="checkbox" onChange={()=>this.onClickToogleYear(key)} checked={this.state.checkbox[key]} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            );
        });
        return cBoxList;
    };

    displayList= () => {
        let postList = [];
        data.forEach((post, index)=>{
                if (this.state.checkbox[post.year])
                    postList.push(
                        <ListItem key={index} date={post.month + ", " + post.year} img={post.imgName} text={post.text}/>
                    );
            }
        );
        return postList;
    };

    onClickToogleAll = ()=>{
        let all=!this.state.selectAll;
        let arr = {};
        Object.keys(this.state.checkbox).forEach(
            (key) => arr[key]= all
        );
        this.setState({
            selectAll : all,
            checkbox: arr,
        });
    };

    onClickToogleYear = (year)=>{
        let arr = this.state.checkbox;
        arr[year] = !arr[year];
        this.setState({checkbox: arr});
    };

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const cooperation = lang.ms.cooperation;
        const standards = lang.ms.standards;
        const org = lang.ms.organisations;
        const description = lang.ms.standardsText;
        const orgDescription = lang.ms.organisationsText;

        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <div className="clearfix">
                    <section className="standards-navigation-section">
                        <h2 className="standards-navigation-header">{cooperation.header1}</h2>
                        <ul>
                            <li><a href="#standard-category-1">{standards.standardHeader1}</a></li>
                            <li><a href="#standard-category-2">{standards.standardHeader2}</a></li>
                            <li><a href="#standard-category-3">{standards.standardHeader3}</a></li>
                            <li><a href="#standard-category-4">{standards.standardHeader4}</a></li>
                            <li><a href="#standard-category-5">{standards.standardHeader5}</a></li>
                            <li><a href="#standard-category-6">{standards.standardHeader6}</a></li>
                            <li><a href="#standard-category-7">{standards.standardHeader7}</a></li>
                        </ul>
                    </section>
                    <section className="standards-navigation-categories-section">
                        <div id="standard-category-1">
                            <div className="standard-category-header">{standards.standardHeader1}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText1}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText2}
                                </p>
                            </div>
                        </div>
                        <div id="standard-category-2">
                            <div className="standard-category-header">{standards.standardHeader2}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText3}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText4}
                                </p>
                            </div>
                        </div>
                        <div id="standard-category-3">
                            <div className="standard-category-header">{standards.standardHeader3}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText5}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText6}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText7}
                                </p>
                            </div>
                        </div>
                        <div id="standard-category-4">
                            <div className="standard-category-header">{standards.standardHeader4}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText8}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText9}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText10}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText11}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText12}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText13}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText14}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText15}
                                </p>
                            </div>
                        </div>
                        <div id="standard-category-5">
                            <div className="standard-category-header">{standards.standardHeader5}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText16}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText17}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText18}
                                </p>
                            </div>
                        </div>
                        <div id="standard-category-6">
                            <div className="standard-category-header">{standards.standardHeader6}</div>
                            <div className="standard-category-wrapper">
                                <p className="standard-category-text">
                                    {description.standardsText19}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText20}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText21}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText22}
                                </p>
                                <div className="standard-category-text">
                                    <ul>
                                        <li>{description.standardsText23}</li>
                                        <li>{description.standardsText24}</li>
                                        <li>{description.standardsText25}</li>
                                        <li>{description.standardsText26}</li>
                                    </ul>
                                </div>
                                <p className="standard-category-text">
                                    {description.standardsText27}
                                </p>
                                <div className="standard-category-text">
                                    <ul>
                                        <li>{description.standardsText28}</li>
                                        <li>{description.standardsText29}</li>
                                        <li>{description.standardsText30}</li>
                                        <li>{description.standardsText31}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="standard-category-7">
                            <div className="standard-category-header">{standards.standardHeader7}</div>
                            <div className="standard-category-wrapper">

                                <p className="standard-category-text">
                                    {description.standardsText32}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText33}
                                </p>
                                <p className="standard-category-text">
                                    {description.standardsText34}
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="clearfix">
                    <section className="organisations-navigation-section">
                        <h2 className="organisations-navigation-header">{cooperation.header2}</h2>
                        <ul>
                            <li><a href="#organisations-category-1">{org.organisationHeader1}</a></li>
                            <li><a href="#organisations-category-2">{org.organisationHeader2}</a></li>
                            <li><a href="#organisations-category-3">{org.organisationHeader3}</a></li>
                        </ul>
                    </section>
                    <section className="organisations-navigation-categories-section">
                        <div id="organisations-category-1">
                            <div className="organisation-category-header">{org.organisationHeader1}</div>
                            <div className="organisation-category-wrapper">
                                <p className="organisation-category-text">
                                    {orgDescription.organisationText1}
                                </p>
                            </div>
                        </div>
                        <div id="organisations-category-2">
                            <div className="organisation-category-header">{org.organisationHeader2}</div>
                            <div className="organisation-category-wrapper">
                                <p className="organisation-category-text">
                                    {orgDescription.organisationText2}
                                </p>
                            </div>
                        </div>
                        <div id="organisations-category-3">
                            <div className="organisation-category-header">{org.organisationHeader3}</div>
                            <div className="organisation-category-wrapper">
                                <p className="organisation-category-text">
                                    {orgDescription.organisationText3}
                                </p>
                                <p className="organisation-category-text">
                                    {orgDescription.organisationText4}
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    }
}

export default MS;