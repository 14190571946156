import React from 'react';

const bgImageUrl = require(`../img/desktop/slider-image-about-us.jpg`);


class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const l=lang.about;
        const traits=lang.traits;
        return (
            <React.Fragment>
                <section className="slider-image-section" style={{paddingTop: '0'}}>
                    <div className="slider-img" style={{backgroundImage: `url(${bgImageUrl})`}}></div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">{traits.sliderText}</p>

                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>

                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="about-us-photo-section">
                    <div className="about-us-photo-wrapper">
                        <img src={require("../img/desktop/about-us-photo-1.jpg")} alt=""/>
                        {/*<p className="sepia-text">{l.imageText1}</p>
                        <hr />*/}
                    </div>
                    <div className="about-us-photo-wrapper">
                        <img src={require("../img/desktop/about-us-photo-2.jpg")} alt=""/>
                        {/*<p className="sepia-text">{l.imageText2}</p>
                        <hr />*/}
                    </div>
                    <div className="about-us-photo-wrapper">
                        <img src={require("../img/desktop/about-us-photo-3.jpg")} alt=""/>
                        {/*<p className="purple-text">{l.imageText3}</p>
                        <hr />*/}
                    </div>
                    <div className="about-us-photo-wrapper">
                        <img src={require("../img/desktop/about-us-photo-4.jpg")} alt=""/>
                        {/*<p className="purple-text">{l.imageText4}</p>
                        <hr />*/}
                    </div>
                </section>
                <section className="about-us-history-section">
                    <div className="about-us-history-header">
                        {l.aboutUsHistoryHeader}
                    </div>
                    <div className="about-us-history-body">
                        <p className="about-us-history-text">
                            {l.aboutUsHistoryText1}<b className="highlight-history-text">{l.aboutUsHistoryText2}</b>{l.aboutUsHistoryText3}
                        </p>
                        <div className="about-us-employee-list-wrapper">
                            <p className="about-us-employee-list-header">{l.aboutUsEmployeeListHeader}</p>
                            <p className="about-us-employee-list-item">{l.aboutUsEmployeeListItem1}</p>
                            <p className="about-us-employee-list-item">{l.aboutUsEmployeeListItem2}</p>
                            <p className="about-us-employee-list-item">{l.aboutUsEmployeeListItem3}</p>
                            <p className="about-us-employee-list-item">{l.aboutUsEmployeeListItem4}</p>
                            <p className="about-us-employee-list-item">{l.aboutUsEmployeeListItem5}</p>
                            <div className="about-us-employee-list-item">
                                <div className="about-us-employee-list-inner-wrapper">
                                    {l.aboutUsEmployeeListItemL0}
                                    <p>{l.aboutUsEmployeeListItemL1}</p>
                                    <p>{l.aboutUsEmployeeListItemL2}</p>
                                    <p>{l.aboutUsEmployeeListItemL3}</p>
                                </div>
                            </div>
                        </div>
                        <p className="about-us-history-text">{l.aboutUsHistoryText4}</p>
                        <p className="about-us-history-text">{l.aboutUsHistoryText5}
                            <br/>
                            {l.aboutUsHistoryText6}
                            {l.aboutUsHistoryText7}
                            {l.aboutUsHistoryText8}
                            {l.aboutUsHistoryText9}
                            {l.aboutUsHistoryText10}
                            {l.aboutUsHistoryText11}
                        </p>
                    </div>
                </section>
                <section className="about-us-quality-insurance-section clearfix">
                    <div className="about-us-history-footer">
                        <p>{l.aboutUsHistoryFooter1}<br/>{l.aboutUsHistoryFooter2}</p>
                        <p>{l.aboutUsHistoryFooter3}</p>
                    </div>
                    <div className="about-us-quality-insurance-header">
                        {l.aboutUsQualityInsuranceHeader}
                    </div>
                    <div className="about-us-quality-insurance-body">
                        <div className="about-us-quality-insurance-list clearfix">
                            <p className="about-us-quality-insurance-list-item">{l.aboutUsQualityInsuranceListItem1}</p>

                        </div>
                        <div className="about-us-quality-insurance-inner-wrapper">
                            <p className="about-us-quality-insurance-text">
                                {l.aboutUsQualityInsuranceText1}
                                <b className="highlight-history-text">{l.aboutUsQualityInsuranceText2}</b>
                                {l.aboutUsQualityInsuranceText3}
                            </p>
                            <p className="about-us-quality-insurance-text">
                                {l.aboutUsQualityInsuranceText4}<b className="highlight-history-text">{l.aboutUsQualityInsuranceText5}</b>
                                {l.aboutUsQualityInsuranceText6}
                            </p>

                            <div className="about-us-quality-list-wrapper">
                                <p className="about-us-quality-list-header">{l.aboutUsQualityListHeader}</p>
                                <p className="about-us-quality-list-item">{l.aboutUsQualityListItem1}</p>
                                <p className="about-us-quality-list-item">{l.aboutUsQualityListItem2}</p>
                                <p className="about-us-quality-list-item">{l.aboutUsQualityListItem3}</p>
                                <p className="about-us-quality-list-item">{l.aboutUsQualityListItem4}</p>
                                <p className="about-us-quality-list-item">{l.aboutUsQualityListItem5}</p>
                            </div>
                        </div>
                        <div className="about-us-quality-insurance-footer">
                            <p className="about-us-quality-insurance-text">{l.aboutUsQualityInsuranceText}</p>
                            <div className="about-us-certificate-wrapper">
                                <div className="about-us-certificate-asset-wrapper">
                                    <img src={require("../img/desktop/about-us-certification.jpg")} alt=""/>
                                    <p className="about-us-certificate-image-caption">{l.aboutUsCertificateImageCaption}</p>
                                </div>
                                <div className="about-us-certificate-asset-wrapper">
                                    <img src={require("../img/desktop/about-us-quality-policy-ims.jpg")} alt=""/>
                                    <p className="about-us-certificate-image-caption">{l.aboutUsPolicyImageCaption}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default About;

