import React from 'react';

export class SuzbijanjeKorova extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.weedControl;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-13.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.weedControl}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                        <li><a href="#service-category-2">{l.serviceCategory2a}<i>{l.serviceCategory2b}</i></a></li>
                        <li><a href="#service-category-3">{l.serviceCategory3}</a></li>
                    </ul>
                </section>

                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText11}
                            </p>
                        </div>
                    </div>

                    <div id="service-category-2">
                        <div className="service-category-header">{l.serviceCategory2a}<i>{l.serviceCategory2b}</i></div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText21a}<i>{l.serviceCategoryText21b}</i>{l.serviceCategoryText21c}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText22}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-3">
                        <div className="service-category-header">{l.serviceCategory3}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText31}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText32}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText33}
                            </p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}





