import React from 'react';
import dataRs from './data/listBlogVestiRs.json';
import dataEn from './data/listBlogVestiEn.json';
import SingleItem from './single-news.js';


const ListItem = props =>(

    <div className="blog-list-item" onClick={()=>props.setSingle(props.id)}>
        <div className="blog-list-item-image-wrapper">
            <img src={require("../img/blog/"+props.img[0]+".jpg")} alt="" />
        </div>
        <p className="blog-list-item-description">
            {props.title}
        </p>
        <div className="blog-list-item-date">{props.date}</div>
    </div>
);



class BlogVesti extends React.Component {
    constructor(props) {
        super(props);
        let arr = {};
        dataRs.forEach(el=>{
            arr[el.year]=true;
        });
        this.state = {
            numimg:0,
            selectAll: true,
            blog: true,
            vesti: false,
            singleNews: 0,
            checkbox :  arr,
            data: ((props.lang==="en")?dataEn:dataRs)
        };
    };

    componentDidMount() {
        window.scrollTo(0,0);
    }

    displayCBoxes= () => {
        const lang = require('./langs/' + this.props.lang + '.json');
        const n = lang.blogNews;
            let cBoxList = ([
                <div className={"sidebar-blog-checkbox-wrapper"+(this.state.blog ? "": " hide")} key={"all"}>
                    <label className="sidebar-blog-checkbox-container">{n.announcements}
                        <input type="checkbox" onChange={this.onClickToogleAll} checked={this.state.selectAll}/>
                        <span className="checkmark"></span>
                    </label>
                </div>
            ]);
            Object.keys(this.state.checkbox).sort().reverse().forEach((key)=>{
                cBoxList.push(
                    <div className={"sidebar-blog-checkbox-wrapper"+(this.state.blog ? "": " hide")} key={key}>
                        <label className="sidebar-blog-checkbox-container">{key}
                            <input type="checkbox" onChange={()=>this.onClickToogleYear(key)} checked={this.state.checkbox[key]}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                );
            });
            return cBoxList;

    };

    displayList= () => {
        let postList = [];
        if (this.state.singleNews===0){
            this.state.data.sort().reverse().forEach((post, index)=>{
                    if (this.state.checkbox[post.year])
                        postList.push(
                            <ListItem key={index} date={post.month + ", " + post.year} img={post.imgName} title={post.title} id={post.id}
                                      setSingle={(id)=>this.onClickShowSingleNews(id)} />
                        );
                }
            );
            if ( postList.length <1) postList.push(
                <section className="single-news-section">
                        <p className="service-category-text">Odaberite godinu za prikaz vesti</p>
                </section>

            );
            return (
                <section className="blog-list-section clearfix">

                    {postList}

                </section>
            );
        } else {
            let id = this.state.singleNews;
            let news = this.state.data.sort().reverse().find(function(element) {
                return element.id === id
            });

            postList.push(
                <SingleItem key={news.id} date={news.month + ", " + news.year} img={news.imgName} num={this.state.numimg}
                            title={news.title} lang={this.props.lang} text={news.text} id={news.id}
                            setSingle={(id)=>this.onClickShowSingleNews(id)} setNum={(id)=>this.onClickSetImgNum(id)}/>
            );
            return postList;
        }

    };

    onClickToogleAll = ()=>{
        let all=!this.state.selectAll;
        let arr = {};
        Object.keys(this.state.checkbox).forEach(
            (key) => arr[key]= all
        );
        this.setState({
            selectAll : all,
            checkbox: arr,
        });
        this.setState({singleNews: 0});
    };

    onClickToogleBlog = ()=>{
        this.setState(prev => ({blog: !prev.blog}));
        this.setState({singleNews: 0});
    };

    onClickToogleVesti = ()=>{
        this.setState(prev => ({vesti: !prev.vesti}));
        this.setState({singleNews: 0});
    };

    onClickToogleYear = (year)=>{
        let arr = this.state.checkbox;
        arr[year] = !arr[year];
        this.setState({checkbox: arr});
        this.setState({singleNews: 0});
    };

    onClickShowSingleNews(id) {
        this.setState({singleNews: id, numimg:0});

        window.scrollTo(0,0);
    }

    onClickSetImgNum(num) {
        this.setState({numimg: num});
    }

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const n =lang.blogNews;


        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <div className="clearfix">
                    <aside className="sidebar-blog">
                        <div className="sidebar-blog-wrapper">
                            <div className={"sidebar-blog-header"+ (this.state.blog ? " selected": "")} onClick={this.onClickToogleBlog}>{n.blog}</div>
                            {this.displayCBoxes()}
                        </div>

                        <div className="sidebar-blog-wrapper">
                            <div className={"sidebar-blog-header"+ (this.state.vesti ? " selected": "")} onClick={this.onClickToogleVesti}>{n.news}</div>

                        </div>
                    </aside>
                    {(this.state.blog)? this.displayList():""}
                </div>

            </React.Fragment>
        )
    }
}


export default BlogVesti;
