import React from 'react';
import {Link} from "react-router-dom";
import dataList from './data/listHomeData.json';

const hidden = {
    display: 'none'
};
class Home extends React.Component {
    //transparent;

    constructor(props) {
        super(props);
        this.state = {index: 0, images: dataList, time: 10000};
        this.timer=null;
    };

    componentDidMount () {
        let maxIndex = this.state.images.length -1;
        this.timer= setInterval(()=> {
           this.setState({index: (this.state.index<maxIndex) ? this.state.index+1 : 0});
        },this.state.time)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    changeImg = (increment) => {
        let maxIndex = this.state.images.length -1;
        let newIndex;
        if (this.state.index+increment >maxIndex) {
            newIndex = 0;
        }
        else if (this.state.index+increment < 0) {
             newIndex = maxIndex;
        }
        else {
            newIndex = this.state.index+increment;
        }

        this.setState({index: newIndex});
    };

    showImage = () => {
        return require("../img/home/" + this.state.images[this.state.index].imgName )
    };

    preloadImages = () => {
        return (
            <div style={hidden}>
                {this.state.images.map(function(name,index){
                    return <img key={index} rel="preload" className="slider-img-inner" src={require("../img/home/"+ name.imgName)} alt=""/>;
                })}
            </div>
        )
    };

    render () {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const l=lang.home;
        const s=lang.services;
        const traits=lang.traits;

        return (
            <div>
                <section className="slider-image-section">

                    <div className="slider-img" >
                        <button className="carousel-slider-img-button carousel-left" onClick={()=>this.changeImg(-1)}></button>
                        <Link to="/gallery"  onClick={this.onClickDef}>
                            {this.preloadImages()}
                            <img className="slider-img-inner" src={this.showImage()} alt=""/>

                        </Link>
                        <button className="carousel-slider-img-button carousel-right" onClick={() =>this.changeImg(1)}></button>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">{traits.sliderText}</p>

                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>

                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="company-notice-section">
                    {/* <i className="icon-crown"></i> */}

                    <h2 className="company-notice-header">
                        {l.companyNoticeHeader}
                    </h2>
                    <p className="company-notice-text">{l.companyNoticeText1}</p>

                    <p className="company-notice-text">{l.companyNoticeText2}</p>

                    <p className="company-notice-text">{l.companyNoticeText3}</p>

                    <p className="company-notice-text">{l.companyNoticeText4}</p>
                </section>
                <section className="company-intro-section">
                    {/* <i className="icon-crown"></i> */}

                    <h2 className="company-intro-header">
                        {l.companyIntroHeader1} <br className="break-mobile" /> {l.companyIntroHeader2} <br className="break-tablet" /> {l.companyIntroHeader3}
                    </h2>
                    <ul className="company-intro-list">
                        <li className="company-intro-list-item">{l.companyIntroListItem1}</li>
                        <li className="company-intro-list-item">{l.companyIntroListItem2}</li>
                        <li className="company-intro-list-item">{l.companyIntroListItem3}</li>
                        <li className="company-intro-list-item">{l.companyIntroListItem4}</li>
                    </ul>
                </section>
                <section className="company-services-section">
                    <h2 className="company-services-header">{l.companyServicesHeader}</h2>
                    <p className="company-services-description"></p>
                    <div className="services-wrapper clearfix">

                        <Link to="/deratizacija" className="service service-1" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-mouse.svg")} alt="" />
                            <h3 className="service-name">{s.deratization.toUpperCase()}</h3>
                        </Link>


                        <Link to="/dezinsekcija" className="service service-2" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-bug.svg")} alt="" />
                            <h3 className="service-name">{s.dezinsection.toUpperCase()}</h3>
                        </Link>

                        <Link to="/about" className="service-wide service-3" onClick={this.onClickDef} >
                            <img src={require("../img/svg/godine-postojanja-" + this.props.lang + ".svg")} alt="" />
                        </Link>

                        <Link to="/dezinfekcija" className="service service-4" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-liquid.svg")} alt=""/>
                            <h3 className="service-name">{s.dezinfection.toUpperCase()}</h3>
                        </Link>

                        <Link to="/suzbijanje-korova" className="service service-5" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-grass.svg")} alt=""/>
                            <h3 className="service-name">{s.weedControl.toUpperCase()}</h3>
                        </Link>

                        <Link to="/suzbijanje-komaraca" className="service service-6" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-mosquito.svg")} alt=""/>
                            <h3 className="service-name">{s.mosquitoControl.toUpperCase()}</h3>
                        </Link>

                        <Link to="/suzbijanje-krpelja" className="service service-7" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-tick.svg")} alt=""/>
                            <h3 className="service-name">{s.combatingTicks.toUpperCase()}</h3>
                        </Link>

                        <Link to="/fumigacija" className="service service-8" onClick={this.onClickDef}>
                            <img src={require("../img/svg/services-icons-fumes2.svg")} alt=""/>
                            <h3 className="service-name">{s.fumigation.toUpperCase()}</h3>
                        </Link>

                    </div>
                </section>

                {/*
                <section className="company-product-section">
                    <h2 className="company-product-header">{l.companyProductHeader}</h2>

                    <p className="company-product-description">
                        {l.companyProductDescription}
                    </p>
                    <div className="clear-both"></div>
                    <div className="product-wrapper clearfix">


                        <div className="product">
                            <Link to="/product-list" style={{ textDecoration: 'none' }} onClick={this.onClickDef}>
                            <div className="product-inner-wrapper">
                                <h2 className="product-header">{l.offer1head}</h2>
                                <h3 className="product-subheader">{l.offer1subhead}</h3>
                                <p className="product-description">
                                    {l.offer1a}
                                </p>
                                <p className="product-description">
                                    {l.offer1b}
                                </p>
                            </div>
                            <img className="product-image mobile" src={require("../img/mobile/laser.png")} alt=""/>
                            <img className="product-image desktop " src={require("../img/desktop/laser.png")} alt=""/>
                            </Link>
                        </div>


                        <div className="product">
                            <Link to="/product-list#uv1" style={{ textDecoration: 'none' }} onClick={this.onClickDef}>
                            <div className="product-inner-wrapper">
                                <h2 className="product-header">{l.offer2head}</h2>
                                <h3 className="product-subheader">{l.offer2subhead}</h3>
                                <p className="product-description">
                                    {l.offer2a}
                                </p>
                                <p className="product-description">
                                    {l.offer2b}
                                </p>
                            </div>
                            <img className="product-image mobile" src={require("../img/mobile/uv1.png")} alt=""/>
                            <img className="product-image desktop" src={require("../img/desktop/uv1.png")} alt=""/>
                            </Link>
                        </div>
                    </div>
                </section>*/}

                <section className="why-us-section">
                    <h2 className="why-us-header">{l.whyUsHeader}</h2>
                    <p className="why-us-description">
                        {l.whyUsDescription}
                    </p>

                    <div className="reasons-wrapper clearfix">
                        <div className="reason">
                            <div className="reason-header-wrapper clearfix">
                                <img className="reason-icon" src={require("../img/svg/why-us-icons-medal.svg")} alt=""/>
                                <h2 className="reason-header">
                                    <span>{l.reasonHeader1}</span>
                                    <span>{l.reasonHeader2}</span>
                                </h2>
                            </div>
                            <p className="reason-description">
                                {l.reasonDesc1}
                            </p>
                        </div>

                        <div className="reason">
                            <div className="reason-header-wrapper clearfix">
                                <img className="reason-icon" src={require("../img/svg/why-us-icons-time.svg")} alt=""/>
                                <h2 className="reason-header">
                                    <span>{l.reasonHeader3}</span>
                                    <span>{l.reasonHeader4}</span>
                                </h2>
                            </div>
                            <p className="reason-description">
                                {l.reasonDesc2}
                            </p>
                        </div>

                        <div className="reason">
                            <div className="reason-header-wrapper clearfix">
                                <img className="reason-icon" src={require("../img/svg/why-us-icons-user.svg")} alt=""/>
                                <h2 className="reason-header">

                                    <span>{l.reasonHeader5}</span>
                                    <span>{l.reasonHeader6}</span>
                                </h2>
                            </div>

                            <p className="reason-description">
                                {l.reasonDesc3}
                            </p>
                        </div>

                        <div className="reason">
                            <div className="reason-header-wrapper clearfix">
                                <img className="reason-icon" src={require("../img/svg/why-us-icons-gear.svg")} alt=""/>
                                <h2 className="reason-header">
                                    <span>{l.reasonHeader7}</span>
                                    <span>{l.reasonHeader8}</span>
                                </h2>
                            </div>
                            <p className="reason-description">
                                {l.reasonDesc4}
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

export default Home;
