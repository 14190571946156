import React, { Component } from 'react';
import dataEn from './data/listImgEn.json';
import dataRs from './data/listImgRs.json';



class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = { isMouseInside: null, selectedImgIndex: undefined, images: ((props.lang==="en")?dataEn:dataRs), displayedImages: 6, imgLoader: "hide"};
    };

    displayImages = (start) => {


        return (
            <div key={start} className="gallery-sub-section">
                <div className="gallery-items-wrapper clearfix">
                    {
                        this.state.images.slice(start, start + 6).map((image, index) => (
                                <div key={start + index} className="gallery-item">
                                    <div className="gallery-image-wrapper"
                                         onMouseLeave={() => this.mouseLeave()}
                                         onMouseOver={() => this.mouseOver(start + index)}>
                                        <img className="gallery-image"
                                             src={require("../img/gallery/" + image.imgName)} alt=""/>
                                        <div
                                            className={"gallery-image-overlay" + (this.state.isMouseInside === start + index ? '' : ' hide')}
                                            onClick={() => this.onClickActivateFullscreen(start + index)}>
                                            <img src={require("../img/svg/logo-white.svg")} alt=""/>
                                        </div>
                                    </div>
                                    {/* <p className="gallery-item-header">{ image.header }</p>
                                    <p className="gallery-item-sub-header">{ image.subHeader }</p>*/}
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        );
    };

    displayShowMore = (index) => {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const g=lang.gallery;

        if(index < this.state.images.length) {
            return (
                <div className="gallery-loader-wrapper" onClick={ ()=>this.showMore(index) }>
                    <div className={"gallery-loader animate " + (this.state.imgLoader)}></div>
                    <p className="gallery-loader-text">{g.showMore}</p>
                </div>
            )
        }

    };

    showMore = (index) => {
        //set imgLoader to show animate
        this.setState({imgLoader: "",});
        //get images
        //getting images from server
        //if gotten all images diplay images and set imgLoader to hide
        this.setState({imgLoader: "hide", displayedImages: index+6})
    };

    mouseOver = (index) => {
        if(this.state.isMouseInside === null) this.setState({ isMouseInside: index });
    };
    mouseLeave = () => {
        if(this.state.isMouseInside !== null) this.setState({ isMouseInside: null });
    };

    onClickActivateFullscreen = (index )=>{
        this.setState({ selectedImgIndex: index });
    };

    onClickGoLeftRight = (index)=>{
        let max=this.state.images.length-1;
        if (index===-1) this.setState({ selectedImgIndex: max });
        else if (index===max+1) this.setState({ selectedImgIndex: 0 });
        else this.setState({ selectedImgIndex: index });
    };

    dispEl = (index, type, opt="current") => {

        let maxIndex = this.state.images.length -1;
        // check if index is in images return "" if it is not else check opt
        if ((index !==undefined ) && (index<=maxIndex) && (index>= 0)) {
            // if opt is current, index is the same
            // else inc or dec index while not going out of bounds
            switch (opt) {
                case "current":
                    break;
                case "prev":
                    index= (index>0) ? index-1 : maxIndex;
                    break;
                case "next":
                    index= (index<maxIndex) ? index+1 : 0;
                    break;
                default :
                    break;
            }

            //switch by type
            switch (type) {
                case "index":
                    return index+1;
                case "maxIndex":
                    return maxIndex+1;
                case "header":
                    return " " + this.state.images[index].header;
                case "subHeader":
                    return " " + this.state.images[index].subHeader;
                case "image":
                    return require("../img/gallery/" + this.state.images[index].imgName )
                default :
                    return "";
            }
        }
        else {
            return "";
        }
    };

    targetHasClickHandler(event) {
        let el = event.target;
        while(el) {
            if (el.getAttribute('data-click-handler')) {
                return true;
            }
            el = el.parentElement;
        }
        return false;
    }

    render() {
        const imgList = [];
        for (let i=0; i< this.state.displayedImages; i+=6) {
            imgList.push(this.displayImages(i));
        }

        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;

        return (

            <React.Fragment>
                <section className="slider-image-section" style={{paddingTop: '0' }}>
                    {/* <!--<div class="slider-img"></div>--> */}
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="gallery-section">
                    <div className="gallery-sub-section-wrapper">
                        {imgList}
                    </div>

                    {this.displayShowMore(this.state.displayedImages)}
             {/* if there is more images show gallery image loader, when clicked set style to animate, load image and show images , change loader state*/}
                </section>

                <section className={"gallery-fullscreen-section " + (this.state.selectedImgIndex!== undefined ? '' : 'hide')} onClick={(evt) => {
                    if (this.targetHasClickHandler(evt)) return null;
                    else {this.onClickActivateFullscreen()}
                }}>

                    <div data-click-handler="true" className="gallery-fullscreen-view-wrapper clearfix">

                        <div className="gallery-fullscreen-secondary-view-wrapper">
                            <div className="gallery-fullscreen-image-wrapper">
                                <img className="gallery-fullscreen-image" src={this.dispEl(this.state.selectedImgIndex, "image", "prev")} alt="" />
                                <div className="gallery-fullscreen-image-overlay"></div>
                            </div>
                            <div className="gallery-fullscreen-image-caption-wrapper">
                                <p>
                                    <span className="gallery-fullscreen-image-caption-indicator-current">{this.dispEl(this.state.selectedImgIndex, "index", "prev")}</span>
                                    <span>/</span>
                                    <span className="gallery-fullscreen-image-caption-indicator-total">{this.dispEl(this.state.selectedImgIndex, "maxIndex")}</span>
                                    <span>
                                        { /* this.dispEl(this.state.selectedImgIndex, "header", "prev") */}
                                    </span>
                                    <span className="gallery-fullscreen-image-caption-service">
                                        {/* this.dispEl(this.state.selectedImgIndex, "subHeader", "prev") */}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="gallery-fullscreen-primary-view-wrapper">
                            <div className="gallery-fullscreen-button-wrapper">
                                <button className="gallery-fullscreen-button gallery-fullscreen-previous-image-button"
                                        onClick={()=>this.onClickGoLeftRight(this.state.selectedImgIndex-1)}>
                                </button>
                            </div>
                            <div className="float-left">
                                <div className="gallery-fullscreen-image-wrapper" >

                                    <img className="gallery-fullscreen-image" src={this.dispEl(this.state.selectedImgIndex, "image")} alt="" />
                                    <button
                                        className="gallery-fullscreen-button close-fullscreen-image-button clearfix" onClick={()=>this.onClickActivateFullscreen()}></button>
                                </div>
                                <div className="gallery-fullscreen-image-caption-wrapper">
                                    <p>
                                        <span className="gallery-fullscreen-image-caption-indicator-current">{this.dispEl(this.state.selectedImgIndex, "index")}</span>
                                        <span> / </span>
                                        <span className="gallery-fullscreen-image-caption-indicator-total">{this.dispEl(this.state.selectedImgIndex, "maxIndex")}</span>
                                        <span>
                                            {this.dispEl(this.state.selectedImgIndex, "header")}
                                        </span>
                                        <span className="gallery-fullscreen-image-caption-service">
                                            {this.dispEl(this.state.selectedImgIndex, "subHeader")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="gallery-fullscreen-button-wrapper">
                                <button className="gallery-fullscreen-button gallery-fullscreen-next-image-button"
                                        onClick={()=>this.onClickGoLeftRight(this.state.selectedImgIndex+1)}>
                                </button>
                            </div>
                        </div>

                        <div className="gallery-fullscreen-secondary-view-wrapper">
                            <div className="gallery-fullscreen-image-wrapper">
                                <img className="gallery-fullscreen-image" src={this.dispEl(this.state.selectedImgIndex, "image", "next")} alt="" />
                                <div className="gallery-fullscreen-image-overlay"></div>
                            </div>
                            <div className="gallery-fullscreen-image-caption-wrapper">
                                <p>
                                    <span className="gallery-fullscreen-image-caption-indicator-current">{this.dispEl(this.state.selectedImgIndex, "index", "next")}</span>
                                    <span>/</span>
                                    <span className="gallery-fullscreen-image-caption-indicator-total">{this.dispEl(this.state.selectedImgIndex, "maxIndex")}</span>
                                    <span>
                                        {this.dispEl(this.state.selectedImgIndex, "header", "next")}
                                    </span>
                                    <span className="gallery-fullscreen-image-caption-service">
                                        {this.dispEl(this.state.selectedImgIndex, "subHeader", "next")}
                                    </span>
                                </p>
                            </div>
                        </div>

                    </div>

                </section>
            </React.Fragment>


        );
    }
}


export default Gallery;

