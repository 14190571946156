import React from 'react';
import Main from './main.js';
import Footer from './footer';
import Popup from './assesment-popup';


class App extends React.Component {
    constructor() {
        super();
        this.state = {
            showPopup: false,
            lang: "rs",
            //width: window.innerWidth,
        };
    }
    /*
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };
    */

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    onClickChangeLang (event){
        this.setState({lang: event});
    }

    render() {
        /*
        const { width } = this.state;
        const isMobile = width <= 1440;
        if (isMobile) {

        }*/
        return (
            <React.Fragment>
                {this.state.showPopup ?

                    <Popup closePopup={this.togglePopup.bind(this)} lang={this.state.lang}/>

                    :

                    <React.Fragment>
                        <Main openPopup={this.togglePopup.bind(this)} setLang={(e)=>this.onClickChangeLang(e)} lang={this.state.lang} />
                        <Footer openPopup={this.togglePopup.bind(this)} lang={this.state.lang} />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default  App ;

