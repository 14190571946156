import React from "react";
import section from "eslint-plugin-jsx-a11y/lib/util/implicitRoles/section";

class Notification extends React.Component {
    constructor() {
        super();
        this.state = {
            showNotif : true
        };
        this.closeNotification = this.closeNotification.bind(this);
    }


    closeNotification () {
        this.setState({
            showNotif : false
        });
        console.log("sdsd");
    }

    render() {
        if (this.state.showNotif)
        return (
            <section  className="notification">
                <div className="notification__wrapper">

                    <img  src={require("../img/notification/zgrade-1.jpg")} alt="notifikacija"/>
                    <button onClick={ this.closeNotification }>x</button>
                </div>

            </section>
        )
        else return null

    }
}

export default Notification;
