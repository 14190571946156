import React from 'react';



export class Deratizacija extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.deratization;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-22.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.deratization}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                        <li><a href="#service-category-2">{l.serviceCategory2}</a></li>
                        <li><a href="#service-category-3">{l.serviceCategory3}</a></li>
                        <li><a href="#service-category-4">{l.serviceCategory4}</a></li>
                        <li><a href="#service-category-5">{l.serviceCategory5}</a></li>
                        <li><a href="#service-category-6">{l.serviceCategory6}</a></li>
                        <li><a href="#pest-categories">{l.serviceCategory7}</a></li>
                    </ul>
                </section>
                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">{l.serviceCategoryText11}</p>
                            <p className="service-category-text">
                                {l.serviceCategoryText12} <br/>
                                {l.serviceCategoryText13}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText14}
                            </p>
                        </div>

                    </div>
                    <div id="service-category-2">
                        <div className="service-category-header">{l.serviceCategory2}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText21}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-3">
                        <div className="service-category-header">{l.serviceCategory3}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText31}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-4">
                        <div className="service-category-header">{l.serviceCategory4}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText41}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-5">
                        <div className="service-category-header">{l.serviceCategory5}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText51}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-6">
                        <div className="service-category-header">{l.serviceCategory6}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText61}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText62}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText63}
                            </p>
                            <div className="service-category-text">
                                <p className="service-category-text">
                                    {l.serviceCategoryText64}
                                </p>
                                <ul>
                                    <li>
                                        {l.serviceCategoryText65}
                                    </li>
                                    <li>
                                        {l.serviceCategoryText66}
                                    </li>
                                </ul>
                            </div>
                            <p className="service-category-text">
                                {l.serviceCategoryText67}
                            </p>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}

export const DeratizacijaPests = props => {
    const lang = require('.././langs/' + props.lang + '.json');
    const l = lang.deratizationPests;

    return (
        <section className="pests-category-section">
            <div id="pest-categories" className="clearfix">
                <div className="pest-category-header">{l.head}</div>
                <p className="pest-category-description">{l.desc}</p>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest1.head.toUpperCase()}
                            <span>{l.pest1.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest1.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/sivi-pacov.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest2.head.toUpperCase()}
                            <span>{l.pest2.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest2.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/crni-pacov.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest3.head.toUpperCase()}
                            <span>{l.pest3.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest3.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/kucni-mis.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest4.head.toUpperCase()}
                            <span>{l.pest4.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest4.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/poljski-mis.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest5.head.toUpperCase()}
                            <span>{l.pest5.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest5.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/voluharica.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest6.head.toUpperCase()}
                            <span>{l.pest6.span}</span>
                        </h2>
                        <p className="pest-subcategory-description">
                            {l.pest6.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/krtica.jpg")} alt=""/>
                </div>
            </div>
        </section>
    )
};


