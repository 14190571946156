import React from 'react';


export class SuzbijanjeKrpelja extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.combatingTicks;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-8.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.combatingTicks}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                    </ul>
                </section>

                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText11}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText12}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText13}
                            </p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export const SuzbijanjeKrpeljaPests = props => {
    const lang = require('.././langs/' + props.lang + '.json');
    const l = lang.combatingTicksPests;

    return (
        <section className="pests-category-section">
            <div id="pest-categories" className="clearfix">
                <div className="pest-category-header">{l.head}</div>
                <p className="pest-category-description">{l.desc}</p>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest1.head.toUpperCase()}
                            <span>{l.pest1.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest1.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/tvrdi.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest2.head.toUpperCase()}
                            <span>{l.pest2.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest2.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/meki.jpg")} alt=""/>
                </div>
            </div>
        </section>
    )
};


