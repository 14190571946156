import React from 'react';

export class Application extends React.Component {
    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const l=lang.application;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../img/desktop/slider-image.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="application-intro-section">
                    <div className="application-intro-header-wrapper">
                        <div className="application-intro-header">{lang.main.application}</div>
                    </div>
                    <div className="application-intro-body clearfix">
                        <div className="application-intro-description-wrapper">
                            <p className="application-intro-description">
                                {l.applicationIntroDescriptionText1}
                            </p>
                            <p className="application-intro-description">
                                {l.applicationIntroDescriptionText2}
                            </p>
                        </div>
                        <div className="application-intro-description-wrapper">
                            <p className="application-intro-description">
                                {l.applicationIntroDescriptionText3}
                            </p>
                            <p className="application-intro-description">
                                {l.applicationIntroDescriptionText4}
                            </p>
                        </div>
                    </div>
                </section>
                <section className="application-steps-section">
                    <div className="application-steps-header">
                        {l.applicationStepHead}
                    </div>
                    <div className="application-step-wrapper">
                        <div className="application-step-image-wrapper">
                            <img src={require("../img/desktop/aplikacija-korak-1.png")} alt=""/>
                        </div>
                        <div className="application-step-header">1.</div>
                        <p className="application-step-description">
                            {l.applicationStepDesc1}
                        </p>
                    </div>
                    <div className="application-step-wrapper">
                        <div className="application-step-image-wrapper">
                            <img src={require("../img/desktop/aplikacija-korak-2.png")} alt=""/>
                        </div>
                        <div className="application-step-header">2.</div>
                        <p className="application-step-description">
                            {l.applicationStepDesc2}
                        </p>
                    </div>
                    <div className="application-step-wrapper">
                        <div className="application-step-image-wrapper">
                            <img src={require("../img/desktop/aplikacija-korak-3.png")} alt=""/>
                        </div>
                        <div className="application-step-header">3.</div>
                        <p className="application-step-description">
                            {l.applicationStepDesc3}
                        </p>
                    </div>
                    <div className="application-step-wrapper">
                        <p className="application-step-access-text">{l.applicationStepAccessText1}</p>
                        <a className="application-step-send-access-btn"
                           href="http://biospin.rs/app/index.php/stats/login">{l.applicationStepSendAccess}</a>
                        <p className="application-step-access-text-2">{l.applicationStepAccessText2}<span
                            className="semi-bold-text">{l.applicationStepAccessText3}</span>
                            {l.applicationStepAccessText4}
                        </p>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}



