import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export class Dezinsekcija extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.dezinsection;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-20.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.dezinsection}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                        <li><a href="#service-category-2">{l.serviceCategory2}</a></li>
                        <li><a href="#pest-categories">{l.serviceCategory3}</a></li>
                    </ul>
                </section>

                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText11}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-2">
                        <div className="service-category-header">{l.serviceCategory2}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText21}<br/>
                                {l.serviceCategoryText22}<br/>
                                {l.serviceCategoryText23}<br/>
                                {l.serviceCategoryText24}
                            </p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export const DezinsekcijaPests = props => {
    const lang = require('.././langs/' + props.lang + '.json');
    const l = lang.dezinsectionPests;

    return (
    <React.Fragment>
        <section className="pests-category-section">
            <div id="pest-categories" className="clearfix">
                <div className="pest-category-header">{l.head}</div>
                <p className="pest-category-description">{l.desc}</p>
                <div className="pest-category-flexbox-wrapper">
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest1.head.toUpperCase()}
                                <span>{l.pest1.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {ReactHtmlParser(l.pest1.desc)}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/buba-rus.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest2.head.toUpperCase()}
                                <span>{l.pest2.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {ReactHtmlParser(l.pest2.desc)}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/zizak.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest3.head.toUpperCase()}
                                <span>{l.pest3.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {l.pest3.desc}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/zitni-kukuljicar.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest4.head.toUpperCase()}
                                <span>{l.pest4.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {l.pest4.desc}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/mali-brasnar.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest5.head.toUpperCase()}
                                <span>{l.pest5.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {l.pest5.desc}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/zizak-moljac.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest6.head.toUpperCase()}
                                <span>{l.pest6.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {l.pest6.desc}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/brasneni-moljac.jpg")} alt="" />
                    </div>
                    <div className="pest-subcategory">
                        <div className="pest-subcategory-wrapper">
                            <h2 className="pest-subcategory-header">
                                {l.pest7.head.toUpperCase()}
                                <span>{l.pest7.span}</span>
                            </h2>
                            <p className="pest-subcategory-description">
                                {l.pest7.desc}
                            </p>
                        </div>
                        <img className="pest-subcategory-image" src={require("../../img/pests/bakrenasti-moljac.jpg")} alt="" />
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
    )
};


