import React from "react";
import ReactHtmlParser from "react-html-parser";

const SingleItem = props => {
    const lang = require('./langs/'+ props.lang +'.json');
    const back =lang.blogNews.back;
    return (
        <section className="single-news-section">
            <div className="service-category-header">{props.title}</div>
            { (props.num<100)?
                <img className="single-news-image-wide" src={require("../img/blog/" + props.img[props.num] + ".jpg")}
                     alt=""/>
                     :
                <div className="videoWrapper">
                    <iframe title={'"' + props.num + '"'} width="560" height="349" className="single-news-image-wide"  src="https://www.youtube.com/embed/66NkFFTIJCI?start=70"
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen> </iframe>
                </div>
            }

            {props.img.map((el, key) => (<img key={key} className="single-news-image" onClick={() => props.setNum(el.match(/^\d/) ? key : key+100 )}
                     src={require("../img/blog/" + el + ".jpg")} alt=""/>))
            }

            <div className="service-category-wrapper single-news-full">
                {ReactHtmlParser(props.text)}
            </div>
            <button className="news-button" onClick={() => props.setSingle(0)}>{ back }</button>
        </section>
    )
};

export default SingleItem;
