import React from 'react';
import './close.css';

const FormErrors = ({formErrors}) => (
    <div >
        {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0){
                return (
                    <p className={"form-error"} key={i}>{formErrors[fieldName]}</p>
                )
            } else {
                return '';
            }
        })}
    </div>
);

class Popup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service: "-",
            objectType: "",
            area: "",
            name: "",
            phone:"",
            email: "",
            city: "",
            message: "",
            honeypot: "",
            formErrors: {service: "",objectType: "",area: "",name: "",phone:"",email: "",city: "",message: "", sent: ""},
            serviceValid: false,
            objectTypeValid: false,
            areaValid: false,
            nameValid: false,
            phoneValid: false,
            emailValid: false,
            cityValid: false,
            fieldSelect: "",
            honeypotValid: true,
            lang: props.lang
        };
        this.handleInputChange=this.handleInputChange.bind(this);
        this.componentDidMount=this.componentDidMount.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    handleSubmit(event) {

        let name=this.state.name;
        let phone=this.state.phone;
        let email=this.state.email;
        let city=this.state.city;
        let service = this.state.service;
        let objType = this.state.objectType;
        let area = this.state.area;

        let e=this;
        let lang=require('./langs/'+ this.state.lang +'.json');

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.open("POST", "assesment.php",true);
        xmlhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xmlhttp.onreadystatechange = function() {//Call a function when the state changes.
            let message;
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                let response= this.responseText;
                message = lang.response[response];
            }
            else {
                message= lang.response["fail3"];
            }

            e.setState({
                service: "-",
                objectType: "",
                area: "",
                name: "",
                phone:"",
                email: "",
                city: "",
                message: "",
                honeypot: "",
                formErrors: {service: "",objectType: "",area: "",name: "",phone:"",email: "",city: "",message: "", sent: message},
                serviceValid: false,
                objectTypeValid: false,
                areaValid: false,
                nameValid: false,
                phoneValid: false,
                emailValid: false,
                cityValid: false,
                fieldSelect: "",
                honeypotValid: true
            });
        };
        xmlhttp.send(
            "service=" + service +
            "&objType=" + objType +
            "&area=" + area +
            "&name=" + name +
            "&phone=" + phone +
            "&email=" + email +
            "&city=" + city
        );
        event.preventDefault();

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value}, () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let serviceValid = this.state.serviceValid;
        let objectTypeValid = this.state.objectTypeValid;
        let areaValid = this.state.areaValid;
        let nameValid = this.state.nameValid;
        let phoneValid = this.state.phoneValid;
        let emailValid = this.state.emailValid;
        let cityValid = this.state.cityValid;
        let honeypotValid = this.state.honeypotValid;
        let services=["deratizacija" ,"dezinsekcija", "dezinfekcija", "suzbijanje komaraca", "suzbijanje krpelja", "suzbijanje korova", "fumigacija"];
        let objects=["skola", "bolnica" ,"ugostiteljski-objekat", "firma", "magacin", "silos", "prodavnica"];

        let lang=require('./langs/'+ this.state.lang +'.json');
        let error=lang.assesment.errors;
        switch(fieldName) {


            case 'honeypot':
                honeypotValid = (value==="");
                fieldValidationErrors.honeypot = honeypotValid ? '' : error.honeypot;
                break;
            case 'service':
                serviceValid = services.includes(value);
                fieldValidationErrors.service = serviceValid ? '': error.service;
                break;
            case 'objectType':
                objectTypeValid = objects.includes(value);
                fieldValidationErrors.service = objectTypeValid ? '': error.objectType;
                break;
            case 'area':
                areaValid =  (value >= 1);
                fieldValidationErrors.area = areaValid ? '': error.area;
                break;
            case 'name':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '': error.name;
                break;
            case 'phone':
                phoneValid =  (value >= 0);
                fieldValidationErrors.phone = phoneValid ? '': error.phone;
                break;
            case 'email':
                // eslint-disable-next-line
                emailValid = value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)?true:false;
                fieldValidationErrors.email = emailValid ? '' : error.email;
                break;
            case 'city':
                cityValid = value.length >= 2;
                fieldValidationErrors.city = cityValid ? '': error.city;
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            serviceValid: serviceValid,
            objectTypeValid: objectTypeValid,
            areaValid: areaValid,
            nameValid: nameValid,
            phoneValid: phoneValid,
            emailValid: emailValid,
            cityValid: cityValid,
            honeypot: honeypotValid
        }, this.validateForm);
    }

    validateForm() {

        this.setState({formValid:
            this.state.honeypotValid &&this.state.serviceValid && this.state.objectTypeValid && this.state.areaValid && this.state.nameValid && this.state.phoneValid && this.state.emailValid && this.state.cityValid && this.state.messageValid});
    }

    onClickFocus(field){
        this.setState({fieldSelect: field});
    }

    targetHasClickHandler(event) {
        let el = event.target;
        while(el) {
            if (el.getAttribute('data-click-handler')) {
                return true;
            }
            el = el.parentElement;
        }
        return false;
    }

    render() {
        const lang = require('./langs/' + this.props.lang + '.json');
        const a = lang.assesment;
        return (
            <React.Fragment>

                <div className="container">
                    <section className="assessment-popup-section" onClick={(evt) => {
                        if (this.targetHasClickHandler(evt)) return null;
                        else {this.props.closePopup(evt)}
                    }}>
                        <div data-click-handler="true" className="assessment-popup-wrapper">
                            <div className="assessment-popup-header">
                                <button className="close-btn" onClick={this.props.closePopup}></button>
                                <img className="assesment-popup-header-logo" src={require("../img/logo.svg")} alt=""/>
                                <p className="assesment-popup-header-text">{lang.traits.popupText}</p>
                                <div className="form-errors">
                                    <FormErrors formErrors={this.state.formErrors} />
                                </div>
                            </div>
                            <div className="assessment-popup-body">
                                <form onSubmit={this.handleSubmit} autoComplete="off">
                                    <div style={{display: "none"}}>
                                        <label>Keep this field blank</label>
                                        <input type="text" name="honeypot" id="honeypot" value={this.state.honeypot} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="assessment-popup-select clearfix">
                                        <label htmlFor="selectService">{a.f1}</label>
                                        <div className={"assessment-popup-select-wrapper clearfix" + (this.state.fieldSelect==="service" ? " active focus": "")}>
                                            <div className="select-wrapper">
                                                <select id="selectService" className="assessment-select-tag" name="service" value={this.state.service} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("service")} >
                                                    <option value="-">{a.f11}</option>
                                                    <option value="deratizacija">{lang.services.deratization}</option>
                                                    <option value="dezinsekcija">{lang.services.dezinsection}</option>
                                                    <option value="dezinfekcija">{lang.services.dezinfection}</option>
                                                    <option value="suzbijanje komaraca">{lang.services.mosquitoControl}</option>
                                                    <option value="suzbijanje krpelja">{lang.services.combatingTicks}</option>
                                                    <option value="suzbijanje korova">{lang.services.weedControl}</option>
                                                    <option value="fumigacija">{lang.services.fumigation}</option>
                                                </select>
                                            </div>
                                            <i className="assessment-input-icon icon-gray-dropdown"></i>
                                        </div>
                                    </div>
                                    <div className="assessment-popup-select clearfix">
                                        <label htmlFor="selectPropertyType">{a.f2}</label>
                                        <div className={"assessment-popup-select-wrapper clearfix" + (this.state.fieldSelect==="objectType" ? " active focus": "")}>
                                            <div className="select-wrapper">
                                                <select id="selectPropertyType" className="assessment-select-tag" name="objectType" value={this.state.objectType} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("objectType")}>
                                                    <option value="">{a.f22}</option>
                                                    <option value="skola">{a.f23}</option>
                                                    <option value="bolnica">{a.f24}</option>
                                                    <option value="ugostiteljski-objekat">{a.f25}</option>
                                                    <option value="firma">{a.f26}</option>
                                                    <option value="magacin">{a.f27}</option>
                                                    <option value="silos">{a.f28}</option>
                                                    <option value="prodavnica">{a.f29}</option>
                                                </select>
                                            </div>
                                            <i className="assessment-input-icon icon-gray-dropdown"></i>
                                        </div>
                                    </div>
                                    <div className="assessment-popup-input">
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.area!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="area" ? " active focus": "") }>

                                            <div className="input-wrapper">
                                                <input className="assessment-input-tag" type="text"
                                                       placeholder={a.f3} name="area" value={this.state.area} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("area")} />
                                                <i className="assessment-input-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 32.222">
                                                        <path className="svg-assessment-icon"
                                                              d="M24.551,12.132A7.394,7.394,0,0,0,20.617,13.2a6.9,6.9,0,0,0-2.637,2.859,5.808,5.808,0,0,0-5.964-3.925,7.078,7.078,0,0,0-3.59.9,6.533,6.533,0,0,0-2.495,2.6V14.607a2.289,2.289,0,0,0-.669-1.771,2.439,2.439,0,0,0-1.723-.619,2.671,2.671,0,0,0-1.827.64,2.316,2.316,0,0,0-.729,1.835V31.2a2.467,2.467,0,0,0,.71,1.9,2.549,2.549,0,0,0,1.805.66,2.624,2.624,0,0,0,1.825-.66,2.431,2.431,0,0,0,.73-1.9V21.688a6.039,6.039,0,0,1,1.156-3.9,3.831,3.831,0,0,1,3.144-1.429,2.887,2.887,0,0,1,2.413,1,5.47,5.47,0,0,1,.792,3.348V31.2a2.431,2.431,0,0,0,.73,1.9,2.626,2.626,0,0,0,1.825.66,2.549,2.549,0,0,0,1.8-.66,2.467,2.467,0,0,0,.711-1.9V21.688a5.964,5.964,0,0,1,1.176-3.9,3.888,3.888,0,0,1,3.164-1.429,2.887,2.887,0,0,1,2.413,1,5.477,5.477,0,0,1,.792,3.348V31.2a2.47,2.47,0,0,0,.709,1.9,2.553,2.553,0,0,0,1.806.66,2.605,2.605,0,0,0,1.845-.66,2.467,2.467,0,0,0,.71-1.9V20.707q0-4.521-1.724-6.548A6.163,6.163,0,0,0,24.551,12.132Zm23.013,4.581H41.321l4.091-4.576a12.676,12.676,0,0,0,2.082-2.973,6.722,6.722,0,0,0,.614-2.848,4.422,4.422,0,0,0-1.455-3.482,5.638,5.638,0,0,0-3.89-1.293A8.474,8.474,0,0,0,37.536,3.43a1.68,1.68,0,0,0-.413.522,1.5,1.5,0,0,0-.129.648,1.541,1.541,0,0,0,.3.945.854.854,0,0,0,.7.4,1.1,1.1,0,0,0,.473-.112,2.907,2.907,0,0,0,.473-.286,9.19,9.19,0,0,1,1.834-1.057,4.767,4.767,0,0,1,1.88-.386,2.5,2.5,0,0,1,1.82.622,2.386,2.386,0,0,1,.639,1.791,4.353,4.353,0,0,1-.462,1.9,9.811,9.811,0,0,1-1.453,2.1L37.8,16.613a1.941,1.941,0,0,0-.544,1.343,1.359,1.359,0,0,0,.355.958,1.253,1.253,0,0,0,.969.385h8.987q1.419,0,1.419-1.317Q48.983,16.712,47.564,16.713Z"
                                                              transform="translate(-0.983 -1.541)"/>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.name!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="name" ? " active focus": "")}>
                                            <div className="input-wrapper">
                                                <input className="assessment-input-tag" type="text" placeholder={a.f4}
                                                       name="name" value={this.state.name} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("name")}/>
                                                <i className="assessment-input-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                        <path className="svg-assessment-icon"
                                                              d="M48,42.9l-9.6-4.8c-0.9-0.5-1.5-1.4-1.5-2.4v-3.4c0.2-0.3,0.5-0.6,0.7-1c1.2-1.8,2.2-3.7,3-5.8  c1.4-0.6,2.3-2,2.3-3.6v-4c0-1-0.4-1.9-1-2.6v-5.3c0.1-0.6,0.3-3.8-2.1-6.5C37.9,1.2,34.5,0,30,0s-7.9,1.2-9.9,3.5  c-2.4,2.7-2.1,6-2.1,6.5v5.3c-0.6,0.7-1,1.7-1,2.6v4c0,1.2,0.6,2.4,1.5,3.1c0.9,3.6,2.8,6.4,3.5,7.2v3.3c0,1-0.5,1.9-1.4,2.3  l-8.9,4.9C8.8,44.4,7,47.5,7,50.8V54c0,4.7,15,6,23,6s23-1.3,23-6v-3C53,47.5,51.1,44.4,48,42.9z"/>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.phone!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="phone" ? " active focus": "")}>
                                            <div className="input-wrapper">
                                                <input className="assessment-input-tag" type="tel"
                                                       placeholder={a.f5} name="phone" value={this.state.phone} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("phone")}/>
                                                <i className="assessment-input-icon">

                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0"
                                                         width="16.877" height="16.877" viewBox="0 0 16.877 16.877"
                                                         style={{enableBackground: "new 0 0 16.877 16.877"}}
                                                         xmlSpace="preserve">
                                                        <g>
                                                            <path className="svg-assessment-icon" d="M5.301,5.587L1.404,1.688c0.503-0.503,1.005-1.007,1.509-1.511C3.146-0.056,3.522-0.06,3.756,0.17l3.036,3.039
                                                                c0.232,0.232,0.232,0.614-0.003,0.848L6.124,4.721L5.505,5.34C5.429,5.417,5.36,5.499,5.301,5.587z M8.507,11.938
                                                                c-0.638-0.549-1.25-1.132-1.844-1.725C6.07,9.616,5.489,9.006,4.939,8.365c-0.45-0.52-0.538-1.273-0.303-1.907L0.636,2.457
                                                                c-0.931,0.957-0.812,3.33,0.208,5.415c0.438,0.902,1.006,1.716,1.593,2.49c0.586,0.768,1.229,1.494,1.906,2.176
                                                                c0.679,0.681,1.401,1.327,2.171,1.913c0.774,0.589,1.59,1.153,2.486,1.59c2.088,1.019,4.462,1.131,5.418,0.199l-4.001-4.001
                                                                C9.783,12.474,9.029,12.387,8.507,11.938z M16.702,13.119l-3.036-3.037c-0.233-0.23-0.615-0.23-0.848,0.003h-0.002l-0.667,0.666
                                                                l-0.615,0.618c-0.076,0.076-0.159,0.143-0.247,0.205l3.896,3.898c0.504-0.505,1.007-1.007,1.512-1.51
                                                                C16.93,13.729,16.935,13.352,16.702,13.119z"/>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.email!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="email" ? " active focus": "")}>
                                            <div className="input-wrapper">
                                                <input className="assessment-input-tag" type="email" placeholder={a.f6}
                                                       name="email" value={this.state.email} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("email")}/>
                                                <i className="assessment-input-icon">

                                                    <svg version="1.1"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0"
                                                         viewBox="0 0 512 512"
                                                         style={{enableBackground: "new 0 0 512 512"}}
                                                         xmlSpace="preserve">
                                                        <g>
                                                            <path className="svg-assessment-icon" d="M439.7,64.5C393,21.5,334.9,0,265.6,0C192.7,0,130.5,24.4,79.2,73.4s-77,109.9-77,182.8
                                                                c0,69.9,24.4,129.9,73.2,180.3c49,50.3,114.8,75.5,197.6,75.5c49.9,0,98.9-10.2,147-30.8c15.7-6.7,23.3-24.6,16.9-40.4l0,0
                                                                c-6.6-16.3-25.2-23.8-41.4-16.9c-41.8,18-82.7,26.9-122.6,26.9c-63.5,0-112.7-19.3-147.6-58.1c-34.8-38.6-52.2-84.1-52.2-136.2
                                                                c0-56.6,18.7-103.8,56-141.7C166.3,77,212.2,58,266.5,58c50,0,92.3,15.6,126.8,46.7S445,174.9,445,222.2c0,32.3-7.9,59.2-23.7,80.6
                                                                c-15.8,21.5-32.2,32.2-49.2,32.2c-9.2,0-13.8-4.9-13.8-14.8c0-8,0.6-17.4,1.8-28.3L379.5,133h-66.8l-4.3,15.6
                                                                c-17-13.9-35.7-20.9-56-20.9c-32.2,0-59.7,12.8-82.6,38.4c-23,25.6-34.4,58.6-34.4,98.9c0,39.4,10.1,71.2,30.5,95.4
                                                                c20.4,24.2,44.8,36.2,73.4,36.2c25.6,0,47.4-10.8,65.6-32.2c13.7,20.6,33.8,30.8,60.5,30.8c39.2,0,73-17,101.5-51.2
                                                                c28.5-34,42.8-75.2,42.8-123.4C509.8,159.7,486.5,107.6,439.7,64.5z M285.7,304.9c-11.8,15.9-25.9,23.9-42.3,23.9
                                                                c-11.2,0-20.2-5.8-26.9-17.5c-6.9-11.7-10.3-26.2-10.3-43.7c0-21.5,4.8-38.9,14.4-52c9.6-13.2,21.5-19.8,35.7-19.8
                                                                c12.3,0,23.3,4.9,32.9,14.8c9.6,9.9,14.4,23.1,14.4,39.6C303.5,270.7,297.5,288.9,285.7,304.9z"/>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.city!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="city" ? " active focus": "")}>
                                            <div className="input-wrapper">
                                                <input className={"assessment-input-tag" + (this.state.fieldSelect==="city" ? " active focus": "")}
                                                       placeholder={a.f7} name="city" value={this.state.city} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("city")}/>
                                                <i className="assessment-input-icon">

                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xmlnsXlink="http://www.w3.org/1999/xlink"
                                                         version="1.1" id="Capa_1" x="0" y="0"
                                                         viewBox="0 0 430 430"
                                                         style={{enableBackground: "new 0 0 430 430"}}
                                                         xmlSpace="preserve">
                                                        <g>
                                                            <path className="svg-assessment-icon"
                                                                  d="M356.208,107.051c-1.531-5.738-4.64-11.852-6.94-17.205C321.746,23.704,261.611,0,213.055,0
                                                                      C148.054,0,76.463,43.586,66.905,133.427v18.355c0,0.766,0.264,7.647,0.639,11.089c5.358,42.816,39.143,88.32,64.375,131.136
                                                                       c27.146,45.873,55.314,90.999,83.221,136.106c17.208-29.436,34.354-59.259,51.17-87.933c4.583-8.415,9.903-16.825,14.491-24.857
                                                                       c3.058-5.348,8.9-10.696,11.569-15.672c27.145-49.699,70.838-99.782,70.838-149.104v-20.262
                                                                       C363.209,126.938,356.581,108.204,356.208,107.051z M214.245,199.193c-19.107,0-40.021-9.554-50.344-35.939
                                                                       c-1.538-4.2-1.414-12.617-1.414-13.388v-11.852c0-33.636,28.56-48.932,53.406-48.932c30.588,0,54.245,24.472,54.245,55.06
                                                                       C270.138,174.729,244.833,199.193,214.245,199.193z"/>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                        <div className={"assessment-popup-input-wrapper" + (this.state.formErrors.message!== "" ? " invalid-input-form ": "") + (this.state.fieldSelect==="message" ? " active focus": "")}>
                                            <div className="textarea-wrapper">
                                                <textarea id="" placeholder={a.f8} name="message" value={this.state.message} onChange={this.handleInputChange} onClick={()=>this.onClickFocus("message")}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"assessment-popup-button" + (this.state.formValid ? " active": "")}>
                                        <button disabled={!this.state.formValid}  type="submit">{a.f30}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    }
}

export default Popup;