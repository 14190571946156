import React from 'react';
import data from './data/listUV.json';


const P = props => {
    return (
        <React.Fragment>
            {props.text}<br />
        </React.Fragment>
    )
};

export class UV extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id : props.id,
        };
    }

    componentDidMount() {
        window.scrollTo(0,0);
        console.log(this.state.id);
    }

    LineBreak(el) {
        return Array.isArray(el)?el.map((el, id) => <P key={id} text={el}/>):el;
    }

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const text=lang.uv;
        const id=this.state.id;

        let uv = data.find(function(element) {
            return element.id === id;
        });

        const pitch = uv[this.props.lang];

        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../img/desktop/slider-image.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="single-product-intro-section">
                    <p className="single-product-info-row">
                        {pitch.intro}
                    </p>
                </section>

                <section className="single-product-info-section">
                    <div className="single-product-info-header-wrapper">
                        <div className="single-product-info-header">{uv.name}</div>
                    </div>

                    <div className="single-product-info-body">
                        <div className="single-product-info-body clearfix">
                            <div className="single-product-info-image-wrapper">
                                <img className="single-product-info-image"
                                     src={require("../img/products/uv" + id+ ".png")} alt=""/>
                            </div>
                            <div className="single-product-info-table">
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat1}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val1}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat2}</div>
                                    <div className="single-product-info-table-data">
                                        {pitch.spec.val2}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat3}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val3}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat4}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val4}
                                    </div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat5}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val5}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat6}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val6}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat7}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val7}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}




