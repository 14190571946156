import React from 'react';


export class ProductList extends React.Component {
    state = { product: null};
    onClickActivateProduct = (product)=>{this.setState({product: (this.state.product===product) ? null : product });
    };

    componentDidMount() {
        window.scrollTo(0,0);
        //console.log(this.props.location);
        let hash= window.location.href.split('#').pop();
        let node = document.getElementById(hash);
        if (node) {
            node.scrollIntoView();
        }
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const l=lang.products;
        const overrideStyle = {
           width: '100%'
        };
        return(
             <React.Fragment>
             <section className="slider-image-section">
                 <div className="slider-img">
                     <img className="slider-img-inner" src={require("../../img/home/gallery-image-5.jpg")} alt=""/>
                 </div>
                 <div className="slider-text-wrapper">
                     <p className="slider-text">
                         {traits.sliderText}
                     </p>
                     <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                     <p className="phone-number">{traits.phoneNumber}</p>
                 </div>
             </section>



             <section className="product-types-section uv-device-type-section clearfix">
                 <h2 className="product-types-header">{l.product2020ListTitle2}</h2>
                 <div className="product-types-description">
                     <p>{l.product2020ListText21}</p><br />
                     <p>{l.product2020ListText22}</p><br />

                     <section>
                         <h3>{l.product2020ListText23}</h3>
                         <ul className="product-type-list">
                             <li>{l.product2020ListText24}</li>
                             <li>{l.product2020ListText25}</li>
                             <li>{l.product2020ListText26}</li>
                             <li>{l.product2020ListText27}</li>
                             <li>{l.product2020ListText28}</li>
                             <li>{l.product2020ListText29}</li>
                             <li>{l.product2020ListText30}</li>
                             <li>{l.product2020ListText31}</li>
                             <li>{l.product2020ListText32}</li>
                             <li>{l.product2020ListText33}</li>
                             <li>{l.product2020ListText34}</li>
                             <li>{l.product2020ListText35}</li>
                             <li>{l.product2020ListText36}</li>
                         </ul>
                     </section>

                     <p><strong>{l.product2020ListText38}</strong></p>
                     <p>{l.product2020ListText39}</p><br />
                 </div>

                <div className="product-grid-wrapper-outer">
                    <div className="product-grid-wrapper">
                        <img src={require("../../img/products/uv2020_1.png")} alt="" />
                        <p className="product-grid-info-header">Model FLY CONTROL® 1x2 <br /></p>
                        <div className="product-grid">
                            <div className="tech-specs">
                                <p>Dimenzije:</p>
                                <p>Težina:</p>
                                <p>Jačina: </p>
                                <p>Dužina kabla:</p>
                                <p>Pokrivanje:</p>
                                <p>Izrada:</p>
                                <p>Cevi:</p>
                                <p>Garancija:</p>
                            </div>
                            <div className="product">
                                <p className="clearfix">V: 41 cm Š: 47 cm D: 8 cm</p>
                                <p>5 kg</p>
                                <p>30 W</p>
                                <p>2m</p>
                                <p>Zidna montaža / Slobodno stojeća montaža: do 200m2</p>
                                <p>Bela</p>
                                <p>2 x 15 W  cevi presvučene sa FEP folijom</p>
                                <p>2 godine</p>
                            </div>
                        </div>
                    </div>
                    <div className="product-grid-wrapper">
                        <img src={require("../../img/products/uv2020_2.png")} alt="" />
                        <p className="product-grid-info-header">Model FLY CONTROL® 2x2<br /></p>
                        <div className="product-grid">
                            <div className="tech-specs">
                                <p>Dimenzije:</p>
                                <p>Težina:</p>
                                <p>Jačina: </p>
                                <p>Dužina kabla:</p>
                                <p>Pokrivanje:</p>
                                <p>Izrada:</p>
                                <p>Cevi:</p>
                                <p>Garancija:</p>
                            </div>
                            <div className="product">

                                <p>V: 41 cm Š: 47 cm D: 16 cm</p>
                                <p>10 kg</p>
                                <p>60 W</p>
                                <p>2m</p>
                                <p>Zidna montaža / Slobodno stojeća montaža: do 400m2</p>
                                <p>Bela</p>
                                <p>4 x 15 W  cevi presvučene sa FEP folijom</p>
                                <p>2 godine</p>
                            </div>
                        </div>
                    </div>
                </div>

             </section>


         </React.Fragment>
         );
     }
}




