import React from 'react';



export class Zgrade extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.deratization;
        return (
            <React.Fragment>
                <section className="service-center service-navigation-categories-section ">
                    <h1 className="service-category-header">Dezinsekcija i deratizacija zgrada</h1>
                    <div className="service-category-wrapper">
                        <p className="service-category-text">
                            Preduzeće Bio Spin 30 godina uspešno posluje u oblasti komunalne higijene. Vršimo usluge dezinsekcije i deratizacije stambenih zajednica.
                            Ukoliko imate problema sa gmižućim insektima, bubarusima, bubašvabama, srebrnim ribicama, mravima, kao i sa glodarima, pozovite nas, pružamo besplatnu procenu stanja Vašeg objekta.
                            Pružene usluge obuhvataju sve stambene jedinice, podrumske, tavanske i zajedničke prostorije.
                            Naši obučeni tehničari apliciraju preparate za dezinsekciju u obliku gela i paste, čineći boravak Vas i Vaših ljubimaca potpuno bezbednim nakon tretmana. Podrumske, tavanske i ostale zajedničke prostorije tretiraju se tečnim preparatima. Deratizacija se izvodi postavljanjem deratizacionih kutija sa deratizacionim lepilom.
                            Naše usluge vršimo isključivo na nivou stambenih zajednica, u dogovoru sa skupštinom stanara.
                            Pozovite nas i uverite se u kvalitet naših usluga.
                        </p>
                        <p className="service-category-text">
                            Kontakt telefon: 062/779-203<br />
                            Email: <a href="mailto:info@biospin.rs ">info@biospin.rs </a>
                        </p>
                    </div>

                </section>

            </React.Fragment>
        )
    }
}
