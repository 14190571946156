import React from 'react';

export class SuzbijanjeKomaraca extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const l=lang.mosquitoControl;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-27.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{lang.services.mosquitoControl}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategoryHeader1}</a></li>
                        <li><a href="#service-category-2">{l.serviceCategoryHeader2}</a></li>
                        <li><a href="#service-category-3">{l.serviceCategoryHeader3}</a></li>
                    </ul>
                </section>
                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategoryHeader1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText11}
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText12a}<i>{l.serviceCategoryText12b}</i>{l.serviceCategoryText12c}
                            </p>
                            <div className="service-category-text">
                                <ol>
                                    <li><i>{l.serviceCategoryText13a}</i>{l.serviceCategoryText13b}</li>
                                    <li><i>{l.serviceCategoryText14a}</i>{l.serviceCategoryText14b}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div id="service-category-2">
                        <div className="service-category-header">{l.serviceCategoryHeader2}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText21}
                            </p>
                        </div>
                    </div>
                    <div id="service-category-3">
                        <div className="service-category-header">{l.serviceCategoryHeader3}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">
                                {l.serviceCategoryText31}
                            </p>
                            <div className="service-category-text">
                                <p className="service-category-text">
                                    {l.serviceCategoryText32}
                                </p>
                                <ul>
                                    <li>{l.serviceCategoryText33}</li>
                                    <li>{l.serviceCategoryText34}</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}


export const SuzbijanjeKomaracaPests = props => {
    const lang = require('.././langs/'+ props.lang +'.json');
    const l=lang.mosquitoControlPests;

    return (
        <section className="pests-category-section">
            <div id="pest-categories" className="clearfix">
                <div className="pest-category-header">{l.head}</div>
                <p className="pest-category-description">{l.desc}</p>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest1.head.toUpperCase()}
                            <span>{l.pest1.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest1.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/malaricni.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest2.head.toUpperCase()}
                            <span>{l.pest2.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest2.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/zuta-groznica.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest3.head.toUpperCase()}
                            <span>{l.pest3.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest3.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/obicni.jpg")} alt=""/>
                </div>
                <div className="pest-subcategory">
                    <div className="pest-subcategory-wrapper">
                        <h2 className="pest-subcategory-header">
                            {l.pest4.head.toUpperCase()}
                            <span>{l.pest4.span}</span>
                        </h2>
                        <p className="pest-subcategory-description-long">
                            {l.pest4.desc}
                        </p>
                    </div>
                    <img className="pest-subcategory-image" src={require("../../img/pests/tigrasti.jpg")} alt=""/>
                </div>
            </div>
        </section>
    );
};




