import React from 'react';

export class Dezinfekcija extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const lang = require('.././langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const services=lang.services;
        const l=lang.dezinfection;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../../img/home/gallery-image-21.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>
                <section className="service-navigation-section">
                    <h1 className="service-navigation-header">{services.dezinfection}</h1>
                    <ul>
                        <li><a href="#service-category-1">{l.serviceCategory1}</a></li>
                    </ul>
                </section>

                <section className="service-navigation-categories-section">
                    <div id="service-category-1">
                        <div className="service-category-header">{l.serviceCategory1}</div>
                        <div className="service-category-wrapper">
                            <p className="service-category-text">{l.serviceCategoryText11}</p>
                            <p className="service-category-text">
                                {l.serviceCategoryText21}
                                <ul>
                                    <li>{l.serviceCategoryText22}</li>
                                    <li>{l.serviceCategoryText23}</li>
                                    <li>{l.serviceCategoryText24}</li>
                                </ul>
                            </p>
                            <p className="service-category-text">
                                {l.serviceCategoryText31}
                                <ul>
                                    <li>{l.serviceCategoryText32}</li>
                                    <li>{l.serviceCategoryText33}</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}





