import React from 'react';
import data from './data/listLasers.json';

const P = props => {
    return (
        <React.Fragment>
        {props.text}<br />
        </React.Fragment>
    )
};

const GraphDesc = props => {
    return (
        <div className="single-product-graph-description">
            <p>{props.text.subhead}</p>
            <ul>
                <li>{props.text.list1}</li>
                <li>{props.text.list2}</li>
            </ul>
        </div>
    )
}

export class Laser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id : props.id,
        };
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    LineBreak(el) {
        return Array.isArray(el)?el.map((el, id) => <P key={id} text={el}/>):el;
    }

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const text=lang.laser;

        const id=this.state.id;
        console.log(id);

        let laser = data.find(function(element) {
            return element.id === id;
        });

        const pitch = laser[this.props.lang];




        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-img">
                        <img className="slider-img-inner" src={require("../img/desktop/slider-image.jpg")} alt=""/>
                    </div>
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="single-product-intro-section">
                    <p className="single-product-info-row">
                        {this.LineBreak(pitch.intro)}
                    </p>
                </section>

                <section className="single-product-info-section">
                    <div className="single-product-info-header-wrapper">
                        <div className="single-product-info-header">{laser.name}</div>
                    </div>

                    <div className="single-product-info-body">
                        <div className="single-product-info-body clearfix">
                            <div className="single-product-info-image-wrapper">
                                <img className="single-product-info-image"
                                     src={require("../img/products/laser"+ id+".png")} alt=""/>
                            </div>
                            <div className="single-product-info-table">
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat1}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val1}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{(parseInt(id, 10) ===3)? text.stat2a : text.stat2}</div>
                                    <div className="single-product-info-table-data">
                                        {pitch.spec.val2}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{(parseInt(id, 10) ===3)? text.stat3a : text.stat3}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val3}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat4}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val4}
                                    </div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat5}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val5}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat6}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val6}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat7}</div>
                                    <div className="single-product-info-table-data">{this.LineBreak(pitch.spec.val7)}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat8}</div>
                                    <div className="single-product-info-table-data">{this.LineBreak(pitch.spec.val8)}</div>
                                </div>
                                <div className="single-product-info-row clearfix">
                                    <div className="single-product-info-table-header">{text.stat9}</div>
                                    <div className="single-product-info-table-data">{pitch.spec.val9}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-product-pitch-section">
                    <div className="single-product-pitch-wrapper">
                        <div className="single-product-pitch-icons clearfix">
                            <svg className="pitch-icon" version="1.1" viewBox="0 0 50 50"
                                 enableBackground="new 0 0 50 50" xmlSpace="preserve">
                                <g>
                                    <path fill="#5BAC46" d="M47.426,19.444h-4.678c-0.752,0-1.371-0.414-1.658-1.108c-0.289-0.694-0.143-1.426,0.389-1.957l3.309-3.307
                                                c0.484-0.486,0.754-1.133,0.754-1.82c0-0.688-0.27-1.334-0.754-1.821L40.57,5.215c-0.973-0.972-2.668-0.974-3.643,0l-3.307,3.307
                                                c-0.531,0.53-1.266,0.679-1.957,0.39c-0.695-0.288-1.107-0.907-1.107-1.659V2.574C30.557,1.155,29.4,0,27.98,0h-5.962
                                                c-1.418,0-2.574,1.155-2.574,2.574v4.678c0,0.752-0.414,1.371-1.107,1.659c-0.691,0.29-1.426,0.141-1.957-0.39l-3.307-3.307
                                                c-0.975-0.974-2.67-0.972-3.643,0L5.214,9.43C4.729,9.916,4.46,10.563,4.46,11.251c0,0.687,0.269,1.333,0.754,1.82l3.308,3.307
                                                c0.53,0.531,0.677,1.263,0.39,1.957c-0.287,0.695-0.907,1.108-1.659,1.108H2.575C1.155,19.443,0,20.598,0,22.017v5.961
                                                c0,1.42,1.154,2.576,2.574,2.576h4.678c0.752,0,1.371,0.412,1.659,1.107c0.288,0.693,0.142,1.426-0.39,1.957l-3.308,3.307
                                                c-0.485,0.486-0.754,1.133-0.754,1.82s0.269,1.334,0.754,1.82l4.216,4.217c0.973,0.973,2.668,0.975,3.643,0l3.307-3.309
                                                c0.531-0.529,1.262-0.677,1.957-0.389s1.107,0.907,1.107,1.659v4.678c0,1.42,1.156,2.574,2.574,2.574h5.962
                                                c1.42,0,2.574-1.154,2.574-2.574v-4.678c0-0.752,0.414-1.371,1.107-1.659c0.695-0.289,1.426-0.143,1.957,0.389l3.307,3.309
                                                c0.975,0.975,2.67,0.973,3.643,0l4.217-4.217c0.484-0.484,0.754-1.133,0.754-1.82s-0.27-1.333-0.754-1.82l-3.309-3.307
                                                c-0.531-0.531-0.676-1.264-0.389-1.957c0.287-0.695,0.908-1.107,1.66-1.107h4.678c1.42,0,2.574-1.156,2.574-2.574v-5.963
                                                C50,20.599,48.846,19.444,47.426,19.444z M48.148,27.98c0,0.398-0.324,0.723-0.723,0.723h-4.678c-1.504,0-2.795,0.863-3.371,2.252
                                                s-0.273,2.914,0.791,3.977l3.309,3.308c0.281,0.282,0.281,0.739,0,1.021l-4.217,4.217c-0.281,0.281-0.74,0.283-1.021,0
                                                l-3.307-3.309c-1.064-1.063-2.588-1.363-3.977-0.79c-1.391,0.575-2.252,1.866-2.252,3.37v4.678c0,0.398-0.324,0.723-0.723,0.723
                                                h-5.962c-0.398,0-0.723-0.323-0.723-0.723v-4.678c0-1.504-0.863-2.795-2.252-3.37c-0.466-0.194-0.945-0.288-1.419-0.288
                                                c-0.938,0-1.849,0.37-2.558,1.078l-3.306,3.307c-0.284,0.283-0.741,0.281-1.021,0L6.524,39.26c-0.282-0.283-0.282-0.74,0-1.023
                                                l3.308-3.305c1.063-1.063,1.367-2.588,0.791-3.977c-0.575-1.389-1.867-2.252-3.37-2.252H2.575c-0.398,0-0.723-0.323-0.723-0.723
                                                v-5.962c0-0.398,0.324-0.722,0.723-0.722h4.678c1.503,0,2.795-0.863,3.37-2.252c0.576-1.389,0.272-2.913-0.791-3.976l-3.308-3.306
                                                c-0.282-0.283-0.282-0.741,0-1.022l4.216-4.216c0.282-0.282,0.739-0.282,1.021,0l3.306,3.307c1.063,1.062,2.586,1.367,3.977,0.791
                                                c1.389-0.574,2.252-1.866,2.252-3.369V2.574c0-0.398,0.324-0.722,0.723-0.722h5.96c0.398,0,0.725,0.324,0.725,0.722v4.678
                                                c0,1.503,0.861,2.795,2.252,3.37c1.391,0.576,2.914,0.272,3.977-0.791l3.307-3.307c0.283-0.282,0.74-0.282,1.021,0l4.217,4.216
                                                c0.281,0.282,0.281,0.741,0,1.022l-3.309,3.306c-1.064,1.063-1.367,2.587-0.791,3.976s1.867,2.252,3.371,2.252h4.678
                                                c0.398-0.001,0.723,0.323,0.723,0.721V27.98z"/>
                                    <path fill="#5BAC46" d="M25,16.667c-4.596,0-8.333,3.738-8.333,8.333c0,4.596,3.737,8.334,8.333,8.334
                                                c4.595,0,8.333-3.738,8.333-8.334C33.334,20.405,29.596,16.667,25,16.667z M25,31.48c-3.573,0-6.48-2.907-6.48-6.48
                                                s2.907-6.481,6.48-6.481c3.574,0,6.48,2.908,6.48,6.481S28.574,31.48,25,31.48z"/>
                                </g>
                            </svg>
                            <svg className="pitch-icon" version="1.1" viewBox="56.25 0 37.5 50"
                                 enableBackground="new 56.25 0 37.5 50" xmlSpace="preserve">
                                <g>
                                    <path fill="#5BAC46" d="M75.736,0.305c-0.407-0.407-1.066-0.407-1.473,0C73.528,1.042,56.25,18.465,56.25,31.25
                                                C56.262,41.601,64.649,49.988,75,50c10.351-0.012,18.738-8.399,18.75-18.75C93.75,18.465,76.473,1.042,75.736,0.305z M75,47.916
                                                c-9.205,0-16.667-7.461-16.667-16.666C58.333,20.754,71.685,6.033,75,2.541c3.316,3.49,16.667,18.207,16.667,28.709
                                                C91.667,40.455,84.205,47.916,75,47.916z"/>
                                </g>

                                <g>
                                    <path fill="#5BAC46"
                                          d="M64.583,31.25H62.5c0.007,6.9,5.6,12.492,12.5,12.5v-2.084C69.25,41.66,64.59,37,64.583,31.25z"/>
                                </g>
                            </svg>
                            <svg className="pitch-icon" version="1.1" viewBox="12.096 0 25.809 50"
                                 enableBackground="new 12.096 0 25.809 50" xmlSpace="preserve">
                                <path fill="#5BAC46" d="M32.949,6.145C30.371,2.527,27.104,0,25,0c-2.102,0-5.371,2.527-7.949,6.145
                                            c-1.851,2.598-4.955,8.052-4.955,15.4c0,0.24,0.004,0.478,0.014,0.712c-0.024,0.128-0.018,0.262,0.021,0.388
                                            c0.396,6.589,4.284,10.893,7.442,14.384c2.478,2.738,4.617,5.104,4.617,7.843v4.317c0,0.446,0.361,0.812,0.81,0.812
                                            s0.811-0.362,0.811-0.812v-4.317c0-2.738,2.14-5.104,4.616-7.843c3.331-3.684,7.478-8.266,7.478-15.484
                                            C37.904,14.197,34.8,8.742,32.949,6.145z M13.715,21.544c0-1.611,0.161-3.124,0.428-4.53L24.19,27.063v5.484L13.725,22.082
                                            C13.719,21.904,13.715,21.726,13.715,21.544z M16.034,11.131l8.156,8.157v5.484l-9.621-9.622
                                            C14.975,13.655,15.489,12.31,16.034,11.131z M35.432,15.151l-9.621,9.622v-5.484l8.156-8.157
                                            C34.512,12.31,35.025,13.655,35.432,15.151z M25.811,32.548v-5.483l10.049-10.048c0.268,1.406,0.428,2.919,0.428,4.53
                                            c0,0.181-0.004,0.36-0.012,0.538L25.811,32.548z M33.201,9.606l-7.391,7.391v-5.484l5.225-5.226c0.201,0.259,0.4,0.524,0.595,0.797
                                            C32.096,7.738,32.646,8.585,33.201,9.606z M29.992,5.04l-4.182,4.182v-7.42c0.57,0.218,1.264,0.625,1.98,1.179
                                            C28.533,3.551,29.273,4.247,29.992,5.04z M24.19,1.804v7.418l-4.181-4.181C21.627,3.252,23.155,2.201,24.19,1.804z M18.372,7.084
                                            c0.197-0.277,0.396-0.542,0.594-0.797l5.227,5.226v5.484L16.8,9.606C17.356,8.585,17.905,7.738,18.372,7.084z M20.774,35.941
                                            c-2.754-3.045-5.812-6.428-6.76-11.279L24.19,34.839v5.269C23.264,38.693,22.037,37.336,20.774,35.941z M29.227,35.941
                                            c-1.262,1.395-2.487,2.752-3.416,4.166v-5.271L35.986,24.66C35.038,29.514,31.98,32.896,29.227,35.941z"/>
                            </svg>
                        </div>
                        <div className="single-product-pitch-header">
                            {pitch.head1}
                        </div>
                        <div className="single-product-pitch-description">
                            {this.LineBreak(pitch.desc1)}
                        </div>
                    </div>
                    <div className="single-product-pitch-wrapper">
                        <div className="single-product-pitch-header">
                            {pitch.head2}
                        </div>
                        <div className="single-product-pitch-description">
                            {pitch.desc2}
                        </div>
                    </div>
                    <div className="single-product-pitch-wrapper">
                        <div className="single-product-pitch-header">
                            {pitch.head3}
                        </div>
                        <div className="single-product-pitch-description">
                            {this.LineBreak(pitch.desc3)}
                        </div>
                    </div>
                </section>

                <section className="single-product-graph-section">
                    <div className="single-product-graph-header">
                        {pitch.graph.head}
                    </div>
                    {
                        (Object.keys(pitch.graph.desc).length === 0 )?
                        "":<GraphDesc text={pitch.graph.desc}/>

                    }

                    <div className="single-product-graph">
                        <img src={require("../img/products/"+ pitch.graph.imgName + ".jpg")} alt=""/>
                    </div>
                    {pitch.graph.desc2}
                </section>

                <section className="single-product-youtube">
                    <iframe width="560"  className={"youtube-video"}  height="315" src={laser.youtube.link1} frameBorder="2" title={laser.name}
                            allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    {(laser.youtube.link2!=="")? (
                        <iframe width="560" className={"youtube-video"} height="315" src={laser.youtube.link2} frameBorder="2" title={laser.id}
                        allow="autoplay; encrypted-media" allowFullScreen></iframe>
                        ):""}
                </section>

            </React.Fragment>
        )
    }
}




